import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-numeric-textbox-field',
  template: `
    <kendo-numerictextbox
      [class.k-state-invalid]="showError"
      [formControl]="formControl"
      [placeholder]="to.placeholder ?? ''"
      [min]="to.min ?? defaultOptions.props.min"
      [max]="to.max ?? defaultOptions.props.max"
      [format]="to.format"
      [step]="to.step ?? defaultOptions.props.step"
      [formlyAttributes]="field"
      [attr.data-cy]="props['data-cy'] ?? 'kendo-numeric-textbox-field'"
    ></kendo-numerictextbox>
  `,
  standalone: false,
})
export class KendoNumericTextBoxFieldComponent extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    props: {
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER,
      step: 1,
      format: 'n2',
    },
  };
}
