import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { Address } from '@app/states/case/case.model';
import { FormlyFieldConfig, FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DirectiveModule } from '@shared/directives/directive.module';
import { TranslateExtensionFactory } from './extension/translate.extension';
import { KendoCardFieldComponent } from './fields/card.field';
import { KendoCheckboxFieldComponent } from './fields/checkbox.field';
import { KendoDatePickerFieldComponent } from './fields/datepicker.field';
import { KendoDropDownFieldComponent } from './fields/dropdown.field';
import { KendoExpandableFieldComponent } from './fields/expandable.field';
import { KendoAccordionFieldComponent } from './fields/accordion.field';
import { KendoGroupFieldComponent } from './fields/group.field';
import { KendoImageFieldComponent } from './fields/image.field';
import { KendoLocationSearchFieldComponent } from './fields/location-search.field';
import { KendoNumericTextBoxFieldComponent } from './fields/numeric-textbox.field';
import { KendoCheckboxComplexFieldComponent } from './fields/checkbox-complex.field';
import { KendoOutputFieldComponent } from './fields/output.field';
import { KendoPriceOutputFieldComponent } from './fields/price-output.field';
import { KendoPasswordFieldComponent } from './fields/password.field';
import { KendoPopupFieldComponent } from './fields/popup.field';
import { KendoRadioFieldComponent } from './fields/radio.field';
import { KendoTextBoxFieldComponent } from './fields/textbox.field';
import { FormParsePipe } from './form-parse.pipe';
import { alphaNumericValidator } from './validation/alphanumeric.validation';
import { codeValidator } from './validation/code.validation';
import { emailValidator } from './validation/email.validation';
import { phoneValidator } from './validation/phone.validation';
import { postalCodeValidator } from './validation/postal-code.validation';
import { specialCaseValidator } from './validation/special-case.validation';
import { KendoFormFieldWrapperComponent } from './wrapper/kendo-form-field.wrapper';
import { KendoSeparatorWrapperComponent } from './wrapper/kendo-separator.wrapper';
import { KendoInfoFieldComponent } from './fields/info.field';
import { KendoIncentiveFieldComponent } from './fields/incentive.field';
import { KendoSubmitButtonFieldComponent } from './fields/submit-button.field';
import { verificationValidator } from './validation/verification.validation';
import { KendoMaskedTextBoxFieldComponent } from './fields/masked-textbox.field';
import { ibanValidator } from './validation/iban.validation';
import { sepaValidator } from './validation/sepa.validation';
import { MultiBindExtensionFactory } from './extension/combine.extension';
import { EtrackerFormWrapperComponent } from './wrapper/etracker.wrapper';
import { KendoKKinkOptionFieldComponent } from './fields/kkink-option.field';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ButtonFieldComponent } from './fields/button.field';
import { KendoInfoIconFieldComponent } from './fields/info-icon.field';
import { ParsePlaceholdersWrapperComponent } from './wrapper/parse-placeholders.wrapper';
import { ParsePaceholdersPipe } from '@shared/pipes/parse-placeholders.pipe';
import { AnchorFieldComponent } from './fields/anchor.field';
import { ibanBlzWhitelistValidator } from './validation/iban-blz-whitelist.validation';
import { registerRtpAccordionPreset } from '@shared/ruv-forms/presets/rtp-onboarding/accordion.preset';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { registerRtpCompletedPreset } from '@shared/ruv-forms/presets/rtp-onboarding/completed.preset';
import { RepeatTypeComponent } from './fields/repeat-section.field';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FormattedTextFieldComponent } from './fields/formatted-text.field';
import { fastResponseValidator } from './validation/fast-response.validation';

const WRAPPERS = [
  EtrackerFormWrapperComponent,
  KendoFormFieldWrapperComponent,
  KendoSeparatorWrapperComponent,
  ParsePlaceholdersWrapperComponent,
];

const FIELDS = [
  KendoGroupFieldComponent,
  KendoDatePickerFieldComponent,
  KendoTextBoxFieldComponent,
  KendoMaskedTextBoxFieldComponent,
  KendoPasswordFieldComponent,
  KendoNumericTextBoxFieldComponent,
  KendoRadioFieldComponent,
  KendoCheckboxFieldComponent,
  KendoCheckboxComplexFieldComponent,
  KendoDropDownFieldComponent,
  KendoLocationSearchFieldComponent,
  KendoExpandableFieldComponent,
  KendoAccordionFieldComponent,
  KendoCardFieldComponent,
  KendoPopupFieldComponent,
  KendoOutputFieldComponent,
  KendoPriceOutputFieldComponent,
  KendoImageFieldComponent,
  KendoInfoFieldComponent,
  KendoIncentiveFieldComponent,
  KendoSubmitButtonFieldComponent,
  KendoKKinkOptionFieldComponent,
  ButtonFieldComponent,
  AnchorFieldComponent,
  KendoInfoIconFieldComponent,
  RepeatTypeComponent,
  FormattedTextFieldComponent,
];

export interface RuVFormsModuleConfig {
  loader: Provider;
}

@NgModule({
  declarations: [...FIELDS, ...WRAPPERS, FormParsePipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DateInputsModule,
    DropDownsModule,
    LayoutModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    PopupModule,
    DirectiveModule,
    TranslateModule.forChild(),
    FormlySelectModule,
    PipesModule,
    FormlyPresetModule,
    FormlyModule.forRoot({
      extras: {
        resetFieldOnHide: false,
      },
      wrappers: [
        {
          name: 'eTracker-form-wrapper',
          component: EtrackerFormWrapperComponent,
        },
        {
          name: 'kendo-form-field-wrapper',
          component: KendoFormFieldWrapperComponent,
        },
        {
          name: 'kendo-separator-wrapper',
          component: KendoSeparatorWrapperComponent,
        },
        {
          name: 'parse-placeholder-wrapper',
          component: ParsePlaceholdersWrapperComponent,
        },
      ],
      types: [
        {
          name: 'submit-button',
          component: KendoSubmitButtonFieldComponent,
        },
        {
          name: 'button',
          component: ButtonFieldComponent,
        },
        {
          name: 'anchor',
          component: AnchorFieldComponent,
        },
        {
          name: 'group',
          component: KendoGroupFieldComponent,
        },
        {
          name: 'datepicker',
          component: KendoDatePickerFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'text',
          component: KendoTextBoxFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'masked',
          component: KendoMaskedTextBoxFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'formatted',
          component: FormattedTextFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'phone',
          extends: 'text',
          defaultOptions: {
            modelOptions: {
              updateOn: 'blur',
            },
            validators: {
              validation: ['phone'],
            },
          },
        },
        {
          name: 'email',
          extends: 'text',
          defaultOptions: {
            modelOptions: {
              updateOn: 'blur',
            },
            validators: {
              validation: ['email'],
            },
          },
        },
        {
          name: 'iban',
          extends: 'masked',
          defaultOptions: {
            props: {
              mask: 'LL00 0000 0000 0000 0000 00',
            },
            modelOptions: {
              updateOn: 'blur',
            },
            validators: {
              validation: ['iban'],
            },
          },
        },
        {
          name: 'general-iban',
          extends: 'formatted',
          defaultOptions: {
            props: {
              mask: 'UU00 AAAA 0000 0000 0000 9999 9999 9999 99',
            },
            modelOptions: {
              updateOn: 'blur',
            },
          },
        },
        {
          name: 'password',
          component: KendoPasswordFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'numeric',
          component: KendoNumericTextBoxFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'radio',
          component: KendoRadioFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'kkink-options',
          component: KendoKKinkOptionFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'checkbox',
          component: KendoCheckboxFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'checkbox-complex',
          component: KendoCheckboxComplexFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'dropdown',
          component: KendoDropDownFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'location-search',
          component: KendoLocationSearchFieldComponent,
          wrappers: ['kendo-form-field-wrapper', 'eTracker-form-wrapper'],
        },
        {
          name: 'popup',
          component: KendoPopupFieldComponent,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'output',
          component: KendoOutputFieldComponent,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'price-output',
          component: KendoPriceOutputFieldComponent,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'image',
          component: KendoImageFieldComponent,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'info',
          component: KendoInfoFieldComponent,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'info-icon',
          component: KendoInfoIconFieldComponent,
        },
        {
          name: 'incentive',
          component: KendoIncentiveFieldComponent,
          wrappers: ['kendo-form-field-wrapper'],
        },
        {
          name: 'expandable',
          component: KendoExpandableFieldComponent,
          wrappers: ['kendo-form-field-wrapper'],
        },
        { name: 'repeat', component: RepeatTypeComponent },
        {
          name: 'accordion',
          component: KendoAccordionFieldComponent,
        },
        {
          name: 'card',
          component: KendoCardFieldComponent,
        },
        {
          name: 'address',
          extends: 'group',
          defaultOptions: {
            props: {
              label: 'FORM.ADDRESS.LABEL',
              labelClass: 'col-12',
            },
            fieldGroupClassName: 'grid',
            fieldGroup: [
              {
                key: 'countryCode',
                type: 'dropdown',
                className: 'col-4',
                defaultValue: 'DE',
                props: {
                  translate: true,
                  options: [
                    { label: 'FORM.COUNTRIES.ANDORRA', value: 'AD' },
                    { label: 'FORM.COUNTRIES.BELGIUM', value: 'BE' },
                    { label: 'FORM.COUNTRIES.BRAZIL', value: 'BR' },
                    { label: 'FORM.COUNTRIES.DENMARK', value: 'DK' },
                    { label: 'FORM.COUNTRIES.GERMANY', value: 'DE' },
                    { label: 'FORM.COUNTRIES.FINLAND', value: 'FI' },
                    { label: 'FORM.COUNTRIES.FRANCE', value: 'FR' },
                    { label: 'FORM.COUNTRIES.GREENLAND', value: 'GL' },
                    {
                      label: 'FORM.COUNTRIES.GREAT_BRITAIN',
                      value: 'GB',
                    },
                    { label: 'FORM.COUNTRIES.ITALY', value: 'IT' },
                    {
                      label: 'FORM.COUNTRIES.LICHTENSTEIN',
                      value: 'LI',
                    },
                    { label: 'FORM.COUNTRIES.LUXEMBOURG', value: 'LU' },
                    { label: 'FORM.COUNTRIES.MONACO', value: 'MC' },
                    {
                      label: 'FORM.COUNTRIES.NETHERLANDS',
                      value: 'NL',
                    },
                    { label: 'FORM.COUNTRIES.NORWAY', value: 'NO' },
                    { label: 'FORM.COUNTRIES.AUSTRIA', value: 'AT' },
                    { label: 'FORM.COUNTRIES.POLAND', value: 'PL' },
                    { label: 'FORM.COUNTRIES.PORTUGAL', value: 'PO' },
                    {
                      label: 'FORM.COUNTRIES.PUERTO_RICO',
                      value: 'PR',
                    },
                    { label: 'FORM.COUNTRIES.SWEDEN', value: 'SW' },
                    {
                      label: 'FORM.COUNTRIES.SWITZERLAND',
                      value: 'CH',
                    },
                    { label: 'FORM.COUNTRIES.SLOVAKIA', value: 'SK' },
                    { label: 'FORM.COUNTRIES.SPAIN', value: 'SP' },
                    {
                      label: 'FORM.COUNTRIES.CZECH_REPUBLIC',
                      value: 'CZ',
                    },
                    { label: 'FORM.COUNTRIES.HUNGARY', value: 'HU' },
                    { label: 'FORM.COUNTRIES.USA', value: 'US' },
                  ],
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  className: (field) => {
                    return field.parent?.props?.hideSearch ? 'col-12' : 'col-4';
                  },
                },
              },
              {
                type: 'location-search',
                className: 'col-8',
                props: {
                  placeholder: 'FORM.ADDRESS.SEARCH.PLACEHOLDER',
                  emptyDataText: 'FORM.ADDRESS.SEARCH.NO_DATA',
                  eTrackerObject: 'Adresse',
                  translate: true,
                  filterDelayInMs: 800,
                  minFilterLength: 3,
                  selection: (field: FormlyFieldConfig, event: any | undefined) => {
                    field?.form?.get('street')?.setValue('');
                    field?.form?.get('streetNumber')?.setValue('');
                    field?.form?.get('postalCode')?.setValue('');
                    field?.form?.get('city')?.setValue('');
                    field?.form?.get('additionalAddressInfo')?.setValue('');

                    const selectedAddress = event?.value as Address;
                    if (selectedAddress) {
                      for (const [key, value] of Object.entries(selectedAddress)) {
                        if (key === 'additionalAddressInfo') {
                          // Only customer should fill this out.
                          continue;
                        }
                        field?.form?.get(key)?.setValue(value);
                      }
                    }
                  },
                },
                expressions: {
                  'props.country': 'model.countryCode',
                  hide: (field) => {
                    return field.parent?.props?.hideSearch ?? false;
                  },
                },
              },
              {
                key: 'street',
                type: 'text',
                className: 'col-9',
                defaultValue: null,
                validators: {
                  validation: ['special'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.STREET_NAME.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required ?? false,
                },
              },
              {
                key: 'streetNumber',
                type: 'text',
                className: 'col-3',
                defaultValue: null,
                validators: {
                  validation: ['alphaNumeric'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.STREET_NR.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required ?? false,
                },
              },
              {
                key: 'postalCode',
                type: 'text',
                className: 'col-3',
                defaultValue: null,
                validators: {
                  validation: ['alphaNumeric'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.POSTAL_CODE.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required ?? false,
                },
              },
              {
                key: 'city',
                type: 'text',
                className: 'col-9',
                defaultValue: null,
                validators: {
                  validation: ['special'],
                },
                props: {
                  placeholder: 'FORM.ADDRESS.CITY.PLACEHOLDER',
                  translate: true,
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  'props.required': (field) => field.parent?.props?.required ?? false,
                },
              },
              {
                key: 'additionalAddressInfo',
                type: 'text',
                className: 'col-12',
                defaultValue: null,
                props: {
                  placeholder: 'FORM.ADDRESS.ADDITIONAL_ADDRESS.PLACEHOLDER',
                  translate: true,
                  maxLength: 100,
                  description: 'FORM.ADDRESS.ADDITIONAL_ADDRESS.HINT',
                  eTrackerObject: 'Adresse',
                },
                expressions: {
                  hide: (field) => {
                    return !(field.parent?.props?.showAdditionalAddressInfo ?? false);
                  },
                },
                hooks: {
                  onInit: (field) => {
                    if (!field.parent?.props?.showAdditionalAddressInfo) {
                      field.model.additionalAddressInfo = null; // if the field is not shown, we want always to return null on submit
                    }
                  },
                },
              },
            ],
          },
        },
      ],
      validators: [
        { name: 'code', validation: codeValidator },
        { name: 'email', validation: emailValidator },
        { name: 'postalCode', validation: postalCodeValidator },
        { name: 'phone', validation: phoneValidator },
        { name: 'iban', validation: ibanValidator },
        { name: 'ibanBlzWhitelist', validation: ibanBlzWhitelistValidator },
        { name: 'special', validation: specialCaseValidator },
        { name: 'alphaNumeric', validation: alphaNumericValidator },
        { name: 'requiredTrue', validation: Validators.requiredTrue },
        { name: 'verification', validation: verificationValidator },
        { name: 'fast_response', validation: fastResponseValidator },
        {
          name: 'sepa',
          validation: sepaValidator,
          options: { allowedCountryCodes: [] }, // Empty array for allowedCountryCodes allows all country codes
        },
      ],
      presets: [registerRtpAccordionPreset(), registerRtpCompletedPreset()],
    }),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    CurrencyPipe,
    ParsePaceholdersPipe,
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: TranslateExtensionFactory,
      deps: [TranslateService],
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: MultiBindExtensionFactory,
    },
    provideNgxMask(),
  ],
  exports: [FormlyModule, ReactiveFormsModule, ...FIELDS, ...WRAPPERS],
})
export class RuVFormsModule {}
