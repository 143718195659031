import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'kendo-password-field',
  template: `<kendo-textbox
    #password="kendoTextBox"
    [class.k-state-invalid]="showError"
    [placeholder]="to.placeholder ?? ''"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [attr.data-cy]="props['data-cy'] ?? 'kendo-password-field'"
  >
    <ng-template kendoTextBoxSuffixTemplate>
      <button type="button" (click)="togglePassword()">
        <i class="ruv-icon" [ngClass]="isPasswordVisible ? 'ruv-eye-open' : 'ruv-eye-closed'"></i>
      </button>
    </ng-template>
  </kendo-textbox>`,
  styles: [
    `
      button {
        all: unset;
        cursor: pointer;
      }

      .password {
        display: flex;
        gap: 8px;
      }
    `,
  ],
  standalone: false,
})
export class KendoPasswordFieldComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild('password') passwordField!: TextBoxComponent;

  public isPasswordVisible = false;

  ngAfterViewInit() {
    this.showPassword(false);
  }

  public togglePassword() {
    if (this.isPasswordVisible) {
      this.showPassword(false);
    } else {
      this.showPassword(true);
    }
  }

  private showPassword(visible: boolean) {
    this.passwordField.input.nativeElement.type = visible ? 'text' : 'password';
    this.isPasswordVisible = visible;
  }
}
