import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { BackendService } from '@app/backend/backend.service';
import { Address } from '@app/states/case/case.model';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

const DEFAULT_SEARCH_DELAY_IN_MS = 500;
const DEFAULT_FILTER_LENGTH = 3;

@Component({
  selector: 'kendo-location-search-field',
  template: `
    <kendo-combobox
      [filterable]="true"
      [loading]="loading"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [data]="address$ | async"
      textField="label"
      valueField="value"
      [placeholder]="to.placeholder ?? ''"
      (selectionChange)="to.selection && to.selection(field, $event)"
      [attr.data-cy]="props['data-cy'] ?? 'kendo-location-search-field'"
    >
      <ng-template kendoComboBoxNoDataTemplate>
        <span>
          {{ to.emptyDataText | translate }}
        </span>
      </ng-template>
    </kendo-combobox>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class KendoLocationSearchFieldComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild(ComboBoxComponent, { static: true })
  searchComponent!: ComboBoxComponent;

  public address$!: Observable<{ label: string; value: Address }[]>;
  public loading = false;

  constructor(private locationService: BackendService) {
    super();
  }

  ngOnInit(): void {
    this.initializeFilter();
  }

  private initializeFilter() {
    const filterDelayInMs = (this.props.filterDelayInMs as number) ?? DEFAULT_SEARCH_DELAY_IN_MS;
    const minFilterLength = (this.props.minFilterLength as number) ?? DEFAULT_FILTER_LENGTH;

    const filter$ = this.searchComponent.filterChange.pipe(
      tap(() => this.searchComponent.toggle(false)),
      filter((text) => text.length >= minFilterLength),
      tap(() => (this.loading = true)),
      debounceTime(filterDelayInMs),
      distinctUntilChanged(),
    );

    this.address$ = filter$.pipe(
      switchMap((search) =>
        this.locationService.searchAddress(search, this.props.country ?? '').pipe(
          map((addresses: Address[]) => addresses.map((address) => this.toAddressOption(address))),
          catchError(() => {
            this.loading = false;
            return of([]);
          }),
          tap(() => {
            this.loading = false;
            this.searchComponent.toggle(true);
          }),
        ),
      ),
    );
  }

  private toAddressOption(address: Address): { label: string; value: Address } {
    const additionalInfo =
      address?.additionalAddressInfo && address?.additionalAddressInfo !== address.city
        ? address.additionalAddressInfo
        : '';
    const addressLabel = `${address.street ?? ''} ${
      address.streetNumber ?? ''
    }, ${address.postalCode ?? ''} ${address.city ?? ''}, ${additionalInfo}`;
    return {
      label: addressLabel,
      value: address,
    };
  }
}
