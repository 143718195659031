import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'kendo-datepicker-field',
  template: `
    <kendo-datepicker
      [class.k-state-invalid]="showError"
      [format]="to.format"
      [navigation]="to.navigation"
      [focusedDate]="to.focusedDate"
      [formatPlaceholder]="to.formatPlaceholder"
      [placeholder]="to.placeholder ?? ''"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [attr.data-cy]="props['data-cy'] ?? 'kendo-datepicker-field'"
    >
      <kendo-datetimepicker-messages
        today="{{ 'FORM.DATE_PICKER.TODAY.TEXT' | translate }}"
      ></kendo-datetimepicker-messages>
    </kendo-datepicker>
  `,
  standalone: false,
})
export class KendoDatePickerFieldComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild(DatePickerComponent)
  DatePickerRef: DatePickerComponent | undefined;

  ngAfterViewInit() {
    this.fixScrollingOnIOS();
  }

  private fixScrollingOnIOS(): void {
    /* No idea why this works - but it works.
       Without this fix the page is scrolled to top after typing in the day, the month or any digit of the year on IOS. */
    const datepicker = this.DatePickerRef;

    if (datepicker) {
      const input = datepicker.dateInput;

      const input_caret = (input as any)['caret'];
      (input as any)['caret'] = function (start: any, end: any) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.scrollY;
        return input_caret.call(input, start, end);
      };
    }
  }
}
