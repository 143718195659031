import { Component, QueryList, ViewChildren } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'kendo-accordion-field',
  template: `
    <div class="accordion__wrapper">
      <kendo-label
        class="accordion__label"
        [class]="props.labelClass"
        *ngIf="props.label"
        [text]="props.label"
      ></kendo-label>
      <kendo-expansionpanel
        *ngFor="let item of props.accordionItems; index as i"
        expandIcon="ruv-icon ruv-plus"
        collapseIcon="ruv-icon ruv-minus"
        (action)="onToggle(i)"
      >
        <ng-template kendoExpansionPanelTitleDirective>
          <div
            class="accordion__title"
            [class]="props.itemTitleClass"
            [safeHTML]="props.translateItems ? (item.title ?? '' | translate) : (item.title ?? '')"
          ></div>
        </ng-template>
        <div
          class="accordion__content"
          [class]="props.itemTextClass"
          [safeHTML]="props.translateItems ? (item.text ?? '' | translate) : (item.text ?? '')"
        ></div>
      </kendo-expansionpanel>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      :host ::ng-deep .k-expander {
        overflow: initial;
        border-width: 1px 0;
        border-color: var(--accordion-border-color);
        background-color: white;
        margin: 0px;
        line-height: 1.6;

        &.k-focus::after {
          outline: none;
        }

        + .k-expander.k-expanded,
        &.k-expanded + .k-expander {
          margin: 0;
          border-width: 0 0 1px;
        }

        &.k-expanded,
        .k-expander-header:hover {
          background-color: var(--accordion-expanded-color);
        }

        .k-expander-header {
          align-items: flex-start;
        }

        .k-expander-content {
          padding-top: 0;
        }
      }
    `,
  ],
  standalone: false,
})
export class KendoAccordionFieldComponent extends FieldType<FieldTypeConfig> {
  @ViewChildren(ExpansionPanelComponent)
  public panels!: QueryList<ExpansionPanelComponent>;

  public onToggle(index: number): void {
    this.panels.forEach((panel, idx) => {
      if (idx !== index && panel.expanded) {
        panel.toggle();
      }
    });
  }
}
