import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-info-field',
  template: `
    <div *ngIf="!props.hide" class="info">
      <i class="ruv-icon ruv-icon-tiny ruv-icon-green ruv-info"></i>
      <p class="info--text">
        {{ props.text | translate }}
      </p>
    </div>
  `,
  styles: [
    `
      @use 'colors';

      .info {
        display: flex;
        background-color: colors.$green-1;
        padding: 1rem;
        gap: 8px;
        align-items: center;
        border-radius: 4px;

        &--text {
          font-size: 14px;
          text-align: left;
          letter-spacing: 0px;
          color: var(--primary);
          margin: 0;
        }
      }
    `,
  ],
  standalone: false,
})
export class KendoInfoFieldComponent extends FieldType<FieldTypeConfig> {}
