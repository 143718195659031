import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { initialConfig } from 'ngx-mask';

@Component({
  selector: 'formatted-text-field',
  template: `
    <div class="k-input k-input-md k-input-solid" [class.k-invalid]="showError">
      <input
        class="k-input-inner"
        [class.k-state-invalid]="showError"
        [formControl]="props.disabled ? disabledControl : formControl"
        [formlyAttributes]="field"
        [placeholder]="to.placeholder ?? '' | translate"
        type="text"
        [mask]="props.mask"
        [patterns]="patterns"
        [attr.data-cy]="props['data-cy'] ?? 'formatted-text-field'"
      />
    </div>
  `,
  standalone: false,
})
export class FormattedTextFieldComponent extends FieldType<FieldTypeConfig> {
  // this is an extension of the default patterns defined here https://github.com/JsDaddy/ngx-mask/blob/develop/projects/ngx-mask-lib/src/lib/ngx-mask.config.ts
  // for a detailed explanation on how to use ngx-mask see here https://github.com/JsDaddy/ngx-mask/blob/develop/USAGE.md
  public patterns = {
    ...initialConfig.patterns,
    Z: { pattern: /[A-Za-z0-9]/, optional: true },
  };

  get disabledControl() {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }
}
