import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-card-field',
  template: `
    <kendo-card [className]="field.className" [width]="'100%'">
      <kendo-card-header *ngIf="to.label && !to.hideLabel">
        <header class="card-title">{{ to.label }}</header>
      </kendo-card-header>
      <kendo-card-body>
        <div *ngFor="let f of field.fieldGroup">
          <formly-field [field]="f"></formly-field>
        </div>
      </kendo-card-body>
    </kendo-card>
  `,
  styles: [
    `
      .card-title {
        font-size: 18px;
        font-weight: bold;
      }
    `,
  ],
  standalone: false,
})
export class KendoCardFieldComponent extends FieldType<FieldTypeConfig> {}
