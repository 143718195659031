import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div *ngFor="let contract of field.fieldGroup; let i = index">
      <formly-field [field]="contract"></formly-field>
    </div>
  `,
  standalone: false,
})
export class RepeatTypeComponent extends FieldArrayType {}
