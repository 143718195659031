import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

const PHONENUMBER_DEFAULT_MIN_LENGTH = 9;
const PHONENUMBER_DEFAULT_MAX_LENGTH = 15;

export function phoneValidator(control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const min = field.props?.minLength ?? PHONENUMBER_DEFAULT_MIN_LENGTH;
  const max = field.props?.maxLength ?? PHONENUMBER_DEFAULT_MAX_LENGTH;

  const regex = new RegExp(`^\\+?\\d{${min},${max}}$`);

  const validPhoneNumber = regex.test(control.value);
  return validPhoneNumber ? null : { phone: true };
}
