import { Component, HostListener } from '@angular/core';
import { EtrackerService } from '@app/etracker/etracker.service';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'eTracker-form-wrapper',
  template: ` <ng-container #fieldComponent></ng-container> `,
  standalone: false,
})
export class EtrackerFormWrapperComponent extends FieldWrapper {
  constructor(private eTracker: EtrackerService) {
    super();
  }

  @HostListener('click', ['$event'])
  public onClick(e: PointerEvent) {
    const eTrackerObject = this.props.eTrackerObject ?? this.field.type;
    this.eTracker.sendClickEvent(eTrackerObject, 'form');
  }
}
